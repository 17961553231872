
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(400);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(150);
        App.SetWow(200);

        $('html').addClass('loaded');

        // Carousel combos
        $('[data-component=carousel-combos]').each(function(index, el) {
            $(this).addClass('owl-carousel');
            $(this).owlCarousel({
                loop               : false,
                items              : 6,
                autoplay           : true,
                autoplayTimeout    : 5000,
                nav                : false,
                animateOut         : 'fadeOut',
                dots               : true,
                margin             : 12,
                autoplayHoverPause : true,
                responsive         : {
                    0: {
                        items: 2,
                    },
                    481: {
                        items: 4
                    },
                    993: {
                        items: 6
                    }
                }
            });
        });

        // Carousel cupons
        $('[data-component=carousel-cupons]').each(function(index, el) {
            $(this).addClass('owl-carousel');
            $(this).owlCarousel({
                loop               : false,
                items              : 1,
                autoplay           : true,
                autoplayTimeout    : 5000,
                nav                : false,
                animateOut         : 'fadeOut',
                dots               : true,
                margin             : 10,
                autoplayHoverPause : true,
            });
        });

        // Carousel cardapio
        $('[data-component=carousel-cardapio]').each(function(index, el) {
            $(this).addClass('owl-carousel');
            $(this).owlCarousel({
                loop               : false,
                items              : 1,
                autoplay           : true,
                autoplayTimeout    : 5000,
                nav                : false,
                animateOut         : 'fadeOut',
                dots               : true,
                margin             : 10,
                autoplayHoverPause : true,
            });
        });

        // Open videos popup
        $('.open-video').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });

        // Popup
        if ($('.Popup').length > 0) {
            $.magnificPopup.open({
                items: {
                    src: '.Popup'
                },
                type: 'inline'
            }, 0);
        }

        // Cookies
        if (localStorage.hasOwnProperty('verify_cookies')) {
            $('.Cookies').addClass('_hide');
        }

        $('.Cookies .Button').click(function(event) {
            event.preventDefault();
            localStorage.setItem('verify_cookies', true);
            $('.Cookies').addClass('_hide');
        });

        // Tabs contato
        $(document).on('click', '.tab', function(event) {
            $('.tab').removeClass('_active');
            $(this).addClass('_active');
        });
    });

    jQuery.event.special.touchstart = {
        setup: function( _, ns, handle ){
            this.addEventListener("touchstart", handle, { passive: true });
        }
    };
});